<template>
    <parent-layout :key="id" :title="title">
        <alert-message v-if="isSandbox" type="warning">This is a sandbox instance</alert-message>
        <extended-form
            :form-ready="form.ready"
            :form-data="form.data"
            :disabled="!disableForm || clientSaving || instanceFetching || instanceSaving"
            :show-submit="!isView"
            :submit="submit"
            @formValid="valid = $event"
        >
            <v-row>
                <v-col md="12" cols="12">
                    <v-col cols="12" class="py-0">
                        <div class="d-flex justify-end">
                            <v-menu v-if="isView" offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        class="mr-2"
                                        data-cy="instance-actions-button"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon left>fal fa-chevron-down</v-icon>
                                        Actions
                                    </v-btn>
                                </template>
                                <delete-dialog
                                    v-if="isDeletable"
                                    :delete-action="deleteItem"
                                    :title="`Delete Instance?`"
                                >
                                    <template v-slot:default="{ on, attrs }">
                                        <v-list-item
                                            color="danger"
                                            :disabled="!canDelete"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-list-item-title style="vertical-align: middle">
                                                <v-icon
                                                    size="14"
                                                    color="#050F2D"
                                                    style="min-width: 16px"
                                                    left
                                                >
                                                    fal fa-trash
                                                </v-icon>
                                                Delete
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </delete-dialog>
                                <instance-status-actions
                                    data-cy="instance-actions"
                                    :instance="instance"
                                    with-redirect
                                    list-items
                                />
                            </v-menu>

                            <gated-btn
                                v-if="isView && isEditable"
                                data-cy="instance-edit-button"
                                class="primary"
                                :allowed-roles="[roles.ROLE_IMT_INSTANCES_EDIT]"
                                :to="{ name: 'instance-edit' }"
                            >
                                <v-icon size="14" left> fal fa-pencil </v-icon>
                                Edit
                            </gated-btn>
                        </div>
                    </v-col>
                </v-col>
            </v-row>
            <h4>Client Information</h4>
            <v-row>
                <v-col md="6" cols="12">
                    <v-col cols="12" class="py-0">
                        <auto-complete
                            v-model="client"
                            :form="form"
                            data-cy="client-field"
                            field-key="client"
                            hide-details="auto"
                            label="Client"
                            :items="clients"
                            item-text="displayName"
                            item-value="id"
                            :search-input.sync="search"
                            :readonly="!isCreate"
                            :disabled="!isCreate"
                            :loading="clientFetching"
                            return-object
                            cache-items
                            required
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-title> Search for a client... </v-list-item-title>
                                </v-list-item>
                            </template>
                            <template v-if="client" #append>
                                <router-link
                                    class="pt-1"
                                    :to="{ name: 'client-view', params: { id: client.id } }"
                                >
                                    View
                                </router-link>
                            </template>
                        </auto-complete>
                    </v-col>
                    <v-col v-if="showProductsSelect" cols="12" class="py-0">
                        <drop-down
                            :form="form"
                            item-text="label"
                            item-value="id"
                            data-cy="application-field"
                            field-key="application"
                            :items="clientApplications"
                            label="Application"
                            hide-details="auto"
                            :disabled="!isCreate"
                            required
                        />
                    </v-col>
                </v-col>
            </v-row>
            <div v-if="formApplication">
                <div v-if="clientProducts.length">
                    <h4>Products</h4>
                    <v-row class="ml-1 mb-2">
                        <v-col
                            v-for="(product, index) in clientProducts"
                            :key="product.id"
                            sm="1"
                            class="d-flex flex-row product-list"
                        >
                            <v-checkbox
                                v-model="form.data.products[product.id]"
                                :data-cy="`products-${index}-field`"
                                dense
                                hide-details
                                :value="product.id"
                                :disabled="isView"
                                class="mt-3"
                            />
                            <product-logo
                                :product="{
                                    productName: product.name,
                                    productApp: product.application,
                                }"
                            />
                        </v-col>
                    </v-row>
                </div>
                <h4>Instance Information</h4>
                <v-row>
                    <v-col md="6" cols="12">
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                disabled
                                field-key="id"
                                label="Id"
                                data-cy="id-field"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <drop-down
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="status-field"
                                field-key="status"
                                label="Status"
                                :items="statusChoices"
                            >
                                <template
                                    v-for="(slot, i) in ['selection', 'item']"
                                    v-slot:[slot]="{ item }"
                                >
                                    <chip-cell-instance-status :key="i" :status="item" />
                                </template>
                            </drop-down>
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="database-name-field"
                                field-key="databaseName"
                                label="Database Name"
                                :rules="[isLowerCase]"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="database-host-field"
                                field-key="databaseHost"
                                label="Database Host"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="db-restore-instance-id-field"
                                field-key="dbRestoreInstanceId"
                                label="DB Restore Instance Id"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="expiration-field"
                                field-key="expiration"
                                label="Expiration"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="!isSuperAdmin || isView"
                                data-cy="release-version-field"
                                field-key="releaseVersion"
                                label="Release Version"
                            />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <text-field
                                v-if="showDistrictId"
                                :form="form"
                                :disabled="isView"
                                data-cy="subdomain-field"
                                field-key="subdomain"
                                label="District Identifier"
                                hint="Unique identifier for the instance in the application"
                                persistent-hint
                                :rules="[isLowerCase]"
                            />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="isView"
                                data-cy="display-name-field"
                                field-key="displayName"
                                label="Application Name"
                                hint="This text is displayed in the app picker"
                                persistent-hint
                            />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <text-field
                                :form="form"
                                :disabled="isView"
                                :readonly="isView"
                                data-cy="url-field"
                                field-key="url"
                                label="Url"
                                hint="e.g. https://ec.illuminateed.com/CLIENT_ID"
                            >
                                <template v-if="isView && form.data.url" #append>
                                    <a target="_blank" class="pt-1" :href="form.data.url">
                                        <v-icon x-small>fal fa-share</v-icon>
                                    </a>
                                </template>
                            </text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <auto-complete
                                :form="form"
                                data-cy="state-field"
                                field-key="state"
                                label="State"
                                :items="states"
                                outlined
                                hide-details="auto"
                                item-text="name"
                                item-value="abbr"
                                :disabled="isView"
                                required
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                data-cy="created-at-field"
                                disabled
                                field-key="createdAt"
                                label="Created At"
                            />
                        </v-col>
                        <v-col v-if="isView || isEdit" cols="12" class="py-0">
                            <text-field
                                :form="form"
                                disabled
                                data-cy="updated-at-field"
                                field-key="updatedAt"
                                label="Updated At"
                            />
                        </v-col>
                    </v-col>
                </v-row>
                <div v-if="appDeploymentOptions.length > 0">
                    <h4>Deployment Options</h4>
                    <v-row>
                        <v-col md="6" cols="12">
                            <v-col
                                v-for="option in appDeploymentOptions"
                                :key="option.propertyName"
                                cols="12"
                                class="py-0"
                            >
                                <text-field
                                    v-if="option.type === 'input'"
                                    :form="form"
                                    :disabled="option.disabled || isView"
                                    field-key="deploymentOptions"
                                    :data-cy="`deployment-option-${option.propertyName}-field`"
                                    :label="option.label"
                                    :nested-property="option.propertyName"
                                    :required="option.required"
                                    :hint="option.hint"
                                    persistent-hint
                                />
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <template #actions>
                <v-btn :disabled="!valid || !isLaunchable" @click="saveAndDeploy">
                    Save &amp; Deploy
                </v-btn>
            </template>
        </extended-form>
    </parent-layout>
</template>

<script>
import { between, url } from '@/helpers/form/validation'
import { formSnackbar, successSnackbar, errorSnackbar } from '@/helpers/snackbar.js'
import ExtendedForm from '@/components/form/ExtendedForm.vue'
import ParentLayout from '@/components/layout/ParentLayout'
import TextField from '@/components/form/TextField'
import { mapActions, mapGetters } from 'vuex'
import AutoComplete from '@/components/form/AutoComplete'
import DropDown from '@/components/form/DropDown'
import GatedBtn from '@/components/security/GatedButton'
import roles from '@/helpers/security/roles.js'
import ProductLogo from '@/components/ProductLogo'
import InstanceStatusActions from '@/views/Imt/Instances/StatusActions'
import AlertMessage from '@/components/alert/AlertMessage'
import { safeReferer } from '@/helpers/router'
import DeleteDialog from '@/components/table/DeleteDialog'
import ChipCellInstanceStatus from '@/components/table/ChipCellInstanceStatus'

const MAX_PREFIX_LENGTH = 55

export default {
    name: 'InstanceForm',
    components: {
        AlertMessage,
        InstanceStatusActions,
        ProductLogo,
        GatedBtn,
        DropDown,
        AutoComplete,
        ExtendedForm,
        ParentLayout,
        TextField,
        DeleteDialog,
        ChipCellInstanceStatus,
    },
    props: {
        id: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        roles: roles,
        form: {
            data: {
                id: null,
                status: null,
                databaseName: null,
                databaseHost: null,
                dbRestoreInstanceId: null,
                expiration: null,
                releaseVersion: null,
                createdAt: null,
                updatedAt: null,
                subdomain: null,
                displayName: null,
                state: null,
                application: null,
                url: null,
                deploymentOptions: {},
                client: null,
                clientId: null,
                products: {},
            },
            rules: {
                client: [(v) => !!v || 'Client name is required'],
                application: [(v) => !!v || 'Application is required'],
                state: [(v) => !!v || 'State is required'],
                displayName: [(v) => !!v || 'Application Name is required'],
                url: [(v) => url(v, ['http', 'https'])],
                deploymentOptions: [],
            },
            errors: [],
            ready: false,
        },
        search: null,
        select: null,
        instance: {},
        client: null,
        valid: false,
        // Special deployment options per app
        allDeploymentOptions: {
            allthedata: [
                {
                    type: 'input',
                    label: 'District Name',
                    propertyName: 'districtName',
                    rules: [],
                    hint: 'District Name (displayed within the application)',
                },
            ],
            educlimber: [
                {
                    type: 'input',
                    label: 'District Name',
                    propertyName: 'districtName',
                    rules: [],
                    hint: 'District Name (displayed within the application)',
                },
                {
                    type: 'input',
                    label: 'Database Prefix',
                    propertyName: 'databasePrefix',
                    hint: 'Database Name Prefix (used to create eC database). Example: all_city_leadership',
                    required: true,
                    rules: [
                        (v) => !!v || 'Database Prefix is required',
                        (v) => between(v, 1, MAX_PREFIX_LENGTH),
                        (v) =>
                            /^\w+$/.test(v) ||
                            'Only alphanumeric and underscores characters are allowed',
                    ],
                },
            ],
            fastbridge: [
                {
                    type: 'input',
                    label: 'District Name',
                    propertyName: 'districtName',
                    rules: [],
                    helpText: 'District Name (displayed within the application)',
                },
                {
                    type: 'input',
                    label: 'Salesforce Account Number',
                    propertyName: 'sfAccountNumber',
                    rules: [],
                    disabled: true,
                    hint: '*Field to create a district in Fastbridge. Salesforce account number can be modified in Client Edit page.',
                },
                {
                    type: 'input',
                    label: 'CRM Account Number',
                    propertyName: 'crmAccountNumber',
                    rules: [],
                    // This will be added back in at a later date, commenting it out until then.
                    // rules: [
                    //     (v) => between(v, 1, 255),
                    //     (v) => !!v || 'CRM Account Number is required',
                    // ],
                    disabled: true,
                    hint: '*Required field to create a district in Fastbridge. CRM account number can be modified in Client Edit page.',
                },
                {
                    type: 'input',
                    label: 'NCES ID',
                    propertyName: 'ncesId',
                    rules: [(v) => between(v, 1, 7), (v) => !!v || 'NCES ID is required'],
                    disabled: true,
                    hint: '*Required field to create a district in Fastbridge. NCES ID can be modified in Client Edit page.',
                },
                {
                    type: 'input',
                    label: 'Street Address',
                    propertyName: 'street',
                    helpText: 'Street Address',
                    disabled: true,
                },
                {
                    type: 'input',
                    label: 'City',
                    propertyName: 'city',
                    helpText: 'City',
                    disabled: true,
                },
                {
                    type: 'input',
                    label: 'Zip Code',
                    propertyName: 'zipCode',
                    helpText: 'Zip Code',
                    disabled: true,
                },
            ],
        },
    }),
    computed: {
        ...mapGetters({
            clients: 'client/getItems',
            products: 'product/getItems',
            getClientProducts: 'client/getClientProducts',
            getInstanceProducts: 'instance/getInstanceProducts',
            states: 'usStates/getStates',
            getState: 'usStates/getState',
            clientFetching: 'client/getFetching',
            clientSaving: 'client/getSaving',
            instanceFetching: 'instance/getFetching',
            instanceSaving: 'instance/getSaving',
            getClientById: 'client/getItemById',
            getClientInstances: 'instance/getItemsByField',
            getInstanceById: 'instance/getItemById',
            hasRole: 'auth/hasRole',
        }),
        canDelete() {
            return this.hasRole(roles.ROLE_IMT_INSTANCES_DELETE)
        },
        isDeletable() {
            return ['notDeployed', 'archived'].includes(this.instance.status) && !this.isSandbox
        },
        isLaunchable() {
            return (
                this.isCreate ||
                (this.instance.status === 'notDeployed' && this.form.data.status === 'notDeployed')
            )
        },
        clientIdRouteQuery() {
            return this.$route.query.clientId
        },
        instanceIdRouteParam() {
            return this.$route.params.id
        },
        isEdit: {
            get() {
                return this.$route.name === 'instance-edit'
            },
            cache: false,
        },
        isView() {
            return this.$route.name === 'instance-view'
        },
        isCreate() {
            return this.$route.name === 'instance-create'
        },
        isSandbox() {
            return this.instance?.application?.includes('sandbox')
        },
        isSuperAdmin() {
            return this.hasRole(roles.ROLE_IMT_INSTANCES_SUPER_ADMIN)
        },
        isEditable() {
            return (
                ['notDeployed'].includes(this.instance.status) ||
                this.hasRole(roles.ROLE_IMT_INSTANCES_SUPER_ADMIN)
            )
        },
        title() {
            switch (true) {
                case this.isView:
                    return 'Instance View'
                case this.isEdit:
                    return 'Instance Edit'
                default:
                    return 'Instance Create'
            }
        },
        showProductsSelect() {
            return this.client && !this.clientFetching
        },
        disableForm() {
            if (this.isEdit) {
                return this.hasRole(roles.ROLE_IMT_INSTANCES_EDIT)
            }

            if (this.isCreate) {
                return this.hasRole(roles.ROLE_IMT_INSTANCES_CREATE)
            }

            return this.hasRole(roles.ROLE_IMT_INSTANCES_VIEW)
        },
        appDeploymentOptions() {
            const { application } = this.form.data

            return this.allDeploymentOptions[application] || []
        },
        deploymentOptionRules() {
            return this.appDeploymentOptions.reduce((acc, curr) => {
                acc[curr.propertyName] = curr.rules
                return acc
            }, {})
        },
        clientProducts() {
            const { application } = this.form.data
            if (!this.client) {
                return []
            }

            return this.products.filter((p) => p.application === application)
        },
        statusChoices() {
            return [
                'notDeployed',
                'requestingDeployment',
                'failedDeployment',
                'requestingArchival',
                'active',
                'requestingDeactivation',
                'failedDeactivation',
                'failedActivation',
                'requestingActivation',
                'inactive',
                'failedArchival',
                'requestingRestore',
                'archived',
                'failedRestore',
            ].sort()
        },
        clientApplications() {
            if (!this.isCreate) {
                //Application cannot be modified for created instances so we just return the selected application
                return [{ id: this.instance.application, label: this.instance.displayName }]
            }

            const deployedInstances = this.getClientInstances(this.client.id, 'clientId')
            const products = this.getClientProducts(this.client.id)

            const clientApps = products.reduce((acc, curr) => {
                const instanceExist = deployedInstances.find(
                    (i) => i.application === curr.productApp
                )

                if (this.isCreate && instanceExist) {
                    return acc
                }

                acc[curr.productApp] = {
                    id: curr.productApp,
                    label: curr.applicationDisplayName,
                }

                return acc
            }, {})

            return Object.values(clientApps)
        },
        formApplication() {
            return this.form.data.application
        },
        showDistrictId() {
            // hide district id field for ec apps
            return !['educlimber', 'adspark', 'ad'].includes(this.formApplication)
        },
    },
    watch: {
        instance(instance) {
            const instanceProducts = this.getInstanceProducts(instance.id).reduce((acc, curr) => {
                acc[curr.productId] = curr.productId

                return acc
            }, {})

            //set form data from instance
            this.form.data = Object.keys(this.form.data).reduce((newObj, fieldKey) => {
                if (fieldKey === 'state') {
                    newObj[fieldKey] = this.getState(instance[fieldKey])?.abbr
                    return newObj
                }
                if (fieldKey === 'products') {
                    newObj[fieldKey] = instanceProducts

                    return newObj
                }

                newObj[fieldKey] = instance[fieldKey]
                return newObj
            }, {})
            if (this.client) {
                //set form data client
                this.form.data.client = this.client
                this.form.data.clientId = this.client.id
            }
        },
        clientProducts() {
            if (this.clientProducts.length === 1) {
                this.form.data.products[this.clientProducts[0].id] = this.clientProducts[0].id
            }
        },
        async client() {
            //Clients should only change when instances are created
            if (this.isCreate) {
                await this.fetchClientProducts(this.client.id)
                await this.fetchInstances({ params: { clientId: this.client.id } })
                this.form.data.state = this.getState(
                    this.instance?.state ?? this.client?.state
                )?.abbr

                this.form.data.client = this.client
                this.form.data.clientId = this.client.id
            }
        },
        formApplication(application) {
            if (application) {
                if (!this.isCreate) {
                    return
                }

                const products = this.getClientProducts(this.client.id)
                const appObj = products.find((p) => p.productApp === application)
                this.form.data.displayName = appObj.applicationDisplayName
                this.form.data.deploymentOptions = this.appDeploymentOptions.reduce((acc, curr) => {
                    acc[curr.propertyName] = this.getDeploymentOptionDefaultValue(curr.propertyName)
                    return acc
                }, {})
                this.form.rules.deploymentOptions = this.deploymentOptionRules

                const { displayName } = this.client
                if (application === 'educlimber' && displayName.length) {
                    const formatedPrefix = displayName
                        .toLowerCase()
                        .replace(/ /g, '_')
                        .replace(/[^a-z0-9_]/g, '')
                        .substring(0, MAX_PREFIX_LENGTH)
                    this.form.data.deploymentOptions.databasePrefix = formatedPrefix
                }
            }
        },
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
    },
    async mounted() {
        const clientIdFromRoute = this.clientIdRouteQuery
        const instanceIdFromRoute = this.instanceIdRouteParam
        await this.fetchProducts({})

        // -- CREATE -- //
        if (this.isCreate || !instanceIdFromRoute) {
            if (clientIdFromRoute) {
                // if the client is already loaded, we don't need to load it again.
                if (!this.getClientById(clientIdFromRoute)) {
                    await this.fetchClient(clientIdFromRoute)
                }
                this.client = this.getClientById(clientIdFromRoute)
            }

            this.form.ready = true
            return
        }

        // -- UPDATE / VIEW -- //
        // Always fetch the most recent version of the instance to make sure it's updated.
        await this.fetchInstance(instanceIdFromRoute)
        this.instance = this.getInstanceById(instanceIdFromRoute)
        if (!this.instance) {
            const { path } = safeReferer()
            await this.$router.push({ path })
            return
        }

        if (this.isEdit && !this.isEditable) {
            errorSnackbar({
                subtext: `Instances in ${this.instance.status} status cannot be editted`,
            })
            const { path } = safeReferer()
            await this.$router.push({ path })
            return
        }

        await Promise.all([
            this.fetchClient(this.instance.clientId),
            this.fetchClientProducts(this.instance.clientId),
            this.fetchInstanceProducts(this.instance.id),
        ])

        this.client = this.getClientById(this.instance.clientId)
        if (!this.client) {
            const { path } = safeReferer()
            await this.$router.push({ path })
            return
        }

        this.form.data.client = this.client
        this.form.data.clientId = this.client.id
        this.form.rules.deploymentOptions = this.deploymentOptionRules

        this.form.ready = true
    },
    methods: {
        ...mapActions({
            fetchClient: 'client/get',
            fetchAllClients: 'client/cget',
            fetchClientProducts: 'client/getProducts',
            fetchInstance: 'instance/get',
            fetchInstances: 'instance/cget',
            fetchInstanceProducts: 'instance/getProducts',
            postInstance: 'instance/post',
            postInstanceProducts: 'instance/addProducts',
            fetchInstanceErrors: 'instance/errors',
            patchInstance: 'instance/patch',
            patchInstanceStatus: 'instance/patchStatus',
            deleteInstanceProducts: 'instance/deleteProducts',
            fetchProducts: 'product/cget',
            deleteInstance: 'instance/remove',
        }),
        goBack() {
            const dest = safeReferer('imt-instances')

            this.$router.replace(dest)
        },
        async querySelections(text) {
            if (!this.isCreate) return

            await this.fetchAllClients({ params: { search: { name: text } } })
        },
        getDeploymentOptionDefaultValue(propertyName) {
            const mappedValues = {
                districtName: this.client.displayName,
                //more values here eventually
                city: this.client.city,
                zipCode: this.client.zipCode,
                street: this.client.street,
                ncesId: this.client.ncesId,
                ncesSchoolId: this.client.ncesSchoolId,
                sfAccountNumber: this.client.sfAccountNumber,
                crmAccountNumber: this.client.crmAccountNumber,
            }

            return mappedValues[propertyName] || null
        },
        async deleteItem() {
            await this.deleteInstance({ id: this.instance.id })
            successSnackbar({ message: `Instance deleted` })
            this.goBack()
        },
        async submit() {
            const instance = await this.save()
            if (instance) {
                this.$router.push({
                    name: 'instance-view',
                    params: { id: instance.id },
                })
                this.$forceUpdate()
            }
        },
        async saveAndDeploy() {
            const instance = await this.save()

            // attempt to request deployment.
            if (instance) {
                this.requestDeployment(instance)
                this.setFormDirty(false)
                await this.$router.push({
                    name: 'instance-view',
                    params: { id: instance.id },
                })
                this.$forceUpdate()
            }
        },
        async requestDeployment(instance) {
            const resp = await this.patchInstanceStatus({
                id: instance.id,
                data: { status: 'requestingDeployment' },
            })
            if (resp) {
                this.instance = resp.instance
            }

            return resp
        },
        async save() {
            const formData = { ...this.form.data }

            const data = {
                // hard-code release version value
                instance: { ...formData, releaseVersion: 'production' },
            }

            const instance = await this.saveInstance(data, this.instance.id)
            if (!instance) {
                return null
            }
            this.instance = instance

            if (!(await this.saveProducts(instance))) {
                console.error('Could not save products')
                // I don't want to double create the instance just because products aren't added, but I want to see that they weren't
            }

            formSnackbar({
                isEdit: this.isEdit,
                type: 'Instance',
                identifier: instance.displayName,
            })

            return instance
        },
        async saveInstance(data, id) {
            if (this.isEdit) {
                return (await this.patchInstance({ id, data }))?.instance
            }
            return (await this.postInstance({ data }))?.instance
        },
        async saveProducts(instance) {
            const instanceProducts = this.getInstanceProducts(instance.id)
            const productMap = instanceProducts.reduce((acc, curr) => {
                acc[curr.productId] = curr

                return acc
            }, {})

            const { products } = this.form.data
            const formProducts = Object.keys(products)

            const toDelete = instanceProducts.reduce((acc, curr) => {
                if (!products[curr.productId]) {
                    // Instance Product delete uses the instance_product.id, not the productId
                    acc.push(curr.id)
                }

                return acc
            }, [])

            const toAdd = formProducts.filter((id) => id !== null && !productMap[id])
            const actions = []
            if (toAdd.length > 0) {
                actions.push(
                    this.postInstanceProducts({
                        instanceId: instance.id,
                        data: toAdd,
                    })
                )
            }
            if (toDelete.length > 0) {
                actions.push(
                    this.deleteInstanceProducts({
                        instanceId: instance.id,
                        data: toDelete,
                    })
                )
            }

            await Promise.all(actions)

            formSnackbar({
                isEdit: this.isEdit,
                type: 'Instance',
                identifier: instance.displayName,
            })

            return true
        },
        isLowerCase(v) {
            const apps = ['allthedata', 'allthedata.homeconnect']
            if (!apps.includes(this.formApplication)) {
                return true
            }

            return v?.toLowerCase() === v || 'Value must be lowercase'
        },
    },
}
</script>
